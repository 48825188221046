<template>
  <footer class="footer" @click="toAboutUs">
    <p class="friendLink">Link</p>
    <img class="footerImg" src="../static/img/logo2.png" alt="" />
    <p class="companyName">Lideastech (Shenzhen) Co., Ltd</p>
    <p class="ICPLicensing">ICP: 2021030556号-1</p>
    <p class="address">
      Company address: Building 7, Zone C, Third Industrial Zone, Zhukeng
      Community, Longtian Street, Pingshan District, Shenzhen
    </p>
    <p class="contact">
      Contact number : (0755) 6186 2318 Miss liang (non-working hours:
      13537783630)
    </p>
    <p class="QQ">Company QQ: 2664366058</p>
    <p class="email">Email：hr@bdstechml.com</p>
  </footer>
</template>
<script>
export default {
  data() {
    return {};
  },
    methods: {
      toAboutUs() {
          this.$router.push('/EN/aboutUs')
      }
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: auto;
  box-sizing: border-box;
  cursor: pointer;
  max-width: 2000px;
}
.footer {
  position: relative;
  bottom: 0px;
  height: 300px;
  background: #041d72;
  width: 100%;
  /* width: 100vw; */
}

.footer > p {
  font-family: PingFang SC;
  font-style: normal;
  align-items: center;
  color: #ffffff;
}

.friendLink {
  position: absolute;
  width: 96px;
  height: 33.5px;
  left: 70.5px;
  bottom: 230.5px;
  font-size: 24px;
}

.footerImg {
  position: absolute;
  left: 70.5px;
  bottom: 110px;
  width: 110px;
  height: 110px;
}

.companyName {
  position: absolute;
  width: 234px;
  height: 25px;
  left: 70.5px;
  bottom: 54.5px;
  font-size: 18px;
}

.ICPLicensing {
  position: absolute;
  /* width: 234px; */
  height: 25px;
  /* left: 70.5px; */
    right: 76.5px;
  bottom: 4px;
  font-size: 12px;
}

.address {
  position: absolute;
  width: 397px;
  height: 19.5px;
  right: 47px;
  bottom: 160px;
  font-size: 14px;
}
.contact {
  position: absolute;
  width: 398.5px;
  height: 39px;
  right: 47px;
  bottom: 185px;
  font-size: 14px;
}
.QQ {
  position: absolute;
  /* width: 163.5px; */
  height: 19.5px;
  right: 80px;
  bottom: 87.5px;
  font-size: 14px;
}
.email {
  position: absolute;
  /* width: 165.5px; */
  height: 19.5px;
  right: 80px;
  bottom: 53px;
  font-size: 14px;
}

/*手机端适配*/
@media screen and (max-width: 800px) {
  .friendLink {
    position: absolute;
    width: 96px;
    height: 17px;
    left: 25px;
    bottom: 262.5px;
    font-size: 20px;
  }

  .footerImg {
    position: absolute;
    left: 25px;
    bottom: 125px;
    width: 100px;
    height: 100px;
  }
  .companyName {
    position: absolute;
    width: 96px;
    height: 25px;
    left: 25px;
    bottom: 75px;
    font-size: 13px;
  }
  .address {
    position: absolute;
    width: 180px;
    height: 19.5px;
    right: 20px;
    bottom: 212px;
    font-size: 11px;
  }
  .contact {
    position: absolute;
    width: 180px;
    height: 39px;
    right: 20px;
    bottom: 107px;
    font-size: 12px;
  }
  .QQ {
    position: absolute;
    width: 147.5px;
    height: 19.5px;
    right: 20px;
    bottom: 75.5px;
    font-size: 12px;
  }
  .email {
    position: absolute;
    width: 165.5px;
    height: 19.5px;
    right: 1px;
    bottom: 44px;
    font-size: 12px;
  }
  .ICPLicensing {
  position: absolute;
  width: 234px;
  height: 25px;
  left: 24.5px;
  bottom: 3px;
  font-size: 12px;
}
}
</style>
