import Vue from 'vue';
import progressive from 'progressive-image/dist/vue';
import App from './App.vue';
import router from './router';
import axios from "axios";
import VueAxios from "vue-axios";
import '@/common/font/font.css';

Vue.config.productionTip = false;
Vue.use(progressive, {
  removePreview: true,
  scale: true,
});

Vue.prototype.$axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
});

console.log(process.env.VUE_APP_BASE_API);


new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
