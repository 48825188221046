<template>
  <div id="app">
    <navbar v-show="isShow && CHorEN" @changeLanguage="changeLanguage" />
    <e-navbar v-show="isShow && !CHorEN" @changeLanguage="changeLanguage" />
    <router-view></router-view>
    <float-box v-show="CHorEN" />
    <e-nfloat-box v-show="!CHorEN" />
    <div class="footerBG">
      <index-footer v-show="CHorEN" />
      <e-index-footer v-show="!CHorEN" />
    </div>
  </div>
</template>

<script>
import EIndexFooter from "./components/EIndexFooter.vue";
import ENavbar from "./components/ENavbar.vue";
import ENfloatBox from "./components/ENfloatBox.vue";
import FloatBox from "./components/floatBox.vue";
import IndexFooter from "./components/IndexFooter.vue";
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
    ENavbar,
    FloatBox,
    IndexFooter,
    ENfloatBox,
    EIndexFooter,
  },
  data() {
    return {
      isShow: true,
      CHorEN: true,
      ParkingReservationRouter: false,
    };
  },
  methods: {
    showNav() {
      if (this.$route.name !== "ParkingReservation") {
        const that = this;
        let scrollTop =
          window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        that.scrollTop = scrollTop;
        if (that.scrollTop > 670) {
          that.isShow = false;
        } else {
          that.isShow = true;
        }
      }
    },
    changeLanguage(data) {
      this.CHorEN = !this.CHorEN;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.showNav);
    window.addEventListener(
      "hashchange",
      () => {
        var currentPath = window.location.hash.slice(1); // 获取输入的路由

        if (this.$router.path !== currentPath) {
          this.$router.push(currentPath); // 动态跳转
        }
      },
      false
    );
  },
  destroyed() {
    window.removeEventListener("scroll", this.showNav);
  },
  created() {
    if (this.$route.name === "ParkingReservation") {
      this.ParkingReservationRouter = true;
    }
    this.$axios({
      method: "GET",
      url: "/hello",
    }).then((res) => console.log(res));
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app {
  font-family: PingFang SC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerBG {
  width: 100%;
  background-color: #041d72;
}
</style>
