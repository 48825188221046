<template>
  <div class="navContainer">
     <router-link to="/"><img class="logoImg" src="../static/img/Logo.png" alt="Logo" /></router-link>
    <div class="dropDown">
      <img src="../static/img/nav-list.png" alt="" />
      <ul class="dropNavBar">
        <router-link to="/introduction"><a href=""><li class="dropNavText">企业介绍</li></a></router-link>
        <router-link to="/success"><a href=""><li class="dropNavText">成功案例</li></a></router-link>
        <router-link to="/advantage"><a href=""><li class="dropNavText">企业优势</li></a></router-link>
        <router-link to="/consult"><a href=""><li class="dropNavText">资讯中心</li></a></router-link>
        <router-link to="/joinUs"><a href=""><li class="dropNavText">加入我们</li></a></router-link>
      </ul>
    </div>
    <ul class="navBar">
      <router-link to="/introduction"><a href=""><li class="navText">企业介绍</li></a></router-link>
      <router-link to="/success"><a href=""><li class="navText">成功案例</li></a></router-link>
      <router-link to="/advantage"><a href=""><li class="navText">企业优势</li></a></router-link>
      <router-link to="/consult"><a href=""><li class="navText">资讯中心</li></a></router-link>
      <router-link to="/joinUs"><a href=""><li class="navText">加入我们</li></a></router-link>
    </ul>
    <router-link to="/EN"><p class="changeLanguage" @click="changeLanguage">中/EN</p></router-link>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {};
  },
  methods: {
    changeLanguage() {
      this.$emit('changeLanguage', true)
    }
  },
};
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.changeLanguage{
    position: absolute;
    top: 10px;
    right: 42px;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}
.navContainer {
  position: fixed;
  width: 100%;
  height: 45px;
  left: 0px;
  top: 0px;
  background: rgba(31, 137, 235, 0.5);
  z-index: 99;
}

.navText{
  display: block;
}

.navText:hover {
  font-weight: bold;
}

.navBar {
  display: flex;
  position: absolute;
  right: 20%;
  flex-direction: row;
  align-items: flex-start;
  height: 45px;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .dropDown {
    display: block;
    height: 45px;
    width: 45px;
    position: relative;
    top: 0px;
    left: 0px;
  }

  .dropDown img {
    width: 100%;
    height: 100%;
  }

  .logoImg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
  }

  .navBar {
    display: none;
  }

  .navContainer {
    position: fixed;
    width: 100%;
    height: 45px;
    left: 0px;
    top: 0px;
    background: rgba(31, 137, 235, 0.5);
    z-index: 99;
  }

  .dropNavBar {
    overflow: hidden;
    display: block;
    position: relative;
    top: -5px;
    height: 0px;
    width: 800px;
    transition: all 0.5s;
  }

  .dropDown:hover .dropNavBar {
    height: 270px;/*225 */
    display: block;
    transition: all 0.5s;
  }

  .dropNavText {
    list-style: none;
    height: 45px;
    width: 800px;
    display: inline-block;
    line-height: 45px;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 18px;
    text-align: start;
    color: #ffffff;
    text-decoration: none;
    padding-left: 6px;
    transition: all 0.2s;
    background-color: rgba(31, 137, 235);
    border-bottom: white solid 1px;
  }
}

@media screen and (min-width: 800px) {
  .dropDown,
  .dropNavBar {
    display: none;
    position: relative;
    top: -4px;
  }
  .logoImg {
    position: absolute;
    height: 40px;
    left: 120px;
    top: 3px;
  }
  /* .navBar {
    display: flex;
    position: absolute;
    left: 20%;
    flex-direction: row;
    align-items: flex-start;
    height: 45px;
    text-align: center;
  } */
  .navText {
    list-style: none;
    height: 45px;
    width: 120px;
    display: inline-block;
    line-height: 45px;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    transition: all 0.2s;
    margin: 0 15px;
  }
}

@media screen and (min-width: 800px) and (max-width: 900px) {
  .navText {
    list-style: none;
    height: 45px;
    width: 100px;
    display: inline-block;
    line-height: 45px;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    /* margin: 0 10px; */
    text-decoration: none;
    transition: all 0.2s;
    margin: 0 5px;
  }
  .navBar {
    display: flex;
    position: absolute;
    right: 10%;
    flex-direction: row;
    align-items: flex-start;
    height: 45px;
    text-align: center;
  }
  .logoImg{
    left: 50px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .navText {
    list-style: none;
    height: 45px;
    width: 90px;
    display: inline-block;
    line-height: 45px;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    margin: 0 5px;
    text-decoration: none;
    transition: all 0.2s;
  }
  .navBar{
    right: 15%;
  }
  .logoImg{
    left: 90px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .navText {
    list-style: none;
    height: 45px;
    width: 120px;
    display: inline-block;
    line-height: 45px;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    margin: 0 0 px;
    text-decoration: none;
    transition: all 0.2s;
  }
  .navBar{
    right: 10%;
  }
}

@media screen and (min-width: 1400px) {
  .navText {
    list-style: none;
    height: 45px;
    width: 120px;
    display: inline-block;
    line-height: 45px;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    margin: 0 15px;
    text-decoration: none;
    transition: all 0.2s;
  }
}

/* .navText:hover {
  background: #041d72;
} */

a:hover{
  background: #041d72;
}
</style>
