<template>
    <footer class="footer">
        <div class="footer" @click="toAboutUs">
        <p class="friendLink">友情链接</p>
        <img class="footerImg" src="../static/img/logo2.png" alt="">
        <p class="companyName">乐慧邦科技（深圳）有限公司</p>
        </div>
        <p class="ICPLicensing">粤ICP备2021030556号-1</p>
        <p class="address">公司地址：深圳市坪山区龙田街道竹坑社区金牛东路第三工业区C区7号楼</p>
        <p class="contact">联系电话：(0755) 6186 2318 陈小姐 <br>
             制造工厂：13537783630 梁小姐</p>
        <p class="QQ">公司QQ：2664366058</p>
        <p class="email">邮箱：hr@bdstechml.com</p>
    </footer>
</template>
<script>
export default {
  data() {
    return {
    };
  },

  methods: {
      toAboutUs() {
          this.$router.push('/aboutUs')
      }
  },
};
</script>
<style scoped>
*{
  padding: 0;
  margin: auto;
  box-sizing: border-box;
  cursor: pointer;
  max-width: 2000px;
}
.footer{
    position: relative;
    bottom: 0px;
    height: 300px;
    background: #041D72;
    width: 100%;
    /* width: 100vw; */
}

.footer > p{
    font-family: PingFang SC;
    font-style: normal;
    align-items: center;
    color: #FFFFFF;
}

.friendLink{
    position: absolute;
    width: 96px;
    height: 33.5px;
    left: 70.5px;
    bottom: 230.5px;
    font-size: 24px;
}

.footerImg{
    position: absolute;
    left: 70.5px;
    bottom: 110px;
    width: 110px;
    height: 110px;
}

.companyName{
    position: absolute;
    width: 234px;
    height: 25px;
    left: 70.5px;
    bottom: 54.5px;
    font-size: 18px;
}

.ICPLicensing{
    position: absolute;
    /* width: 234px; */
    height: 25px;
    /* left: 70.5px; */
    right: 48.5px;
    bottom: 25.5px;
    font-size: 12px;
}

.address{
    position: absolute;
/*     width: 397px; */
    height: 19.5px;
    right: 47px;
    bottom: 173px;
    font-size: 14px;
}
.contact{
    position: absolute;
    width: 277.5px;
    height: 39px;
    right: 47px;
    bottom: 119px;
    font-size: 14px;
    text-align: end;
}
.QQ{
    position: absolute;
    width: 147.5px;
    height: 19.5px;
    right: 47px;
    bottom: 87.5px;
    font-size: 14px;
}
.email{
    position: absolute;
    width: 165.5px;
    height: 19.5px;
    right: 49px;
    bottom: 53px;
    font-size: 14px;
}

/*手机端适配*/
@media screen and (max-width: 800px){
    .friendLink{
        position: absolute;
        width: 96px;
        height: 17px;
        left: 25px;
        bottom: 262.5px;
        font-size: 20px;
    }

    .footerImg{
        position: absolute;
        left: 25px;
        bottom: 125px;
        width: 100px;
        height: 100px;
    }
    .companyName{
        position: absolute;
        width: 96px;
        height: 25px;
        left: 25px;
        bottom: 75px;
        font-size: 18px;
    }
    .address{
        position: absolute;
        width: 180px;
        height: 19.5px;
        right: 20px;
        bottom: 240px;
        font-size: 14px;
    }
    .contact{
        position: absolute;
        width: 180px;
        height: 39px;
        right: 20px;
        bottom: 150px;
        font-size: 14px;
    }
    .QQ{
        position: absolute;
        width: 147.5px;
        height: 19.5px;
        right: 20px;
        bottom: 87.5px;
        font-size: 14px;
    }
    .email{
        position: absolute;
        width: 165.5px;
        height: 19.5px;
        right: 20px;
        bottom: 53px;
        font-size: 14px;
    }
}
</style>
