<template>
  <div class="floatButton">
    <a style="text-decoration: none;" href="https://parking.bdstechml.com.cn"><div class="portAppointment" @click="reservation">Parking appoint ment</div></a>
    <img class="wechatQR" src="../static/img/reservation.png" alt="" v-show="reservationMutex">
    <div class="weChat" @click="wechat"><p>WeChat public account</p></div>
    <img class="wechatQR" src="../static/img/wechat.png" alt="" v-show="showQR">
    <div class="contactUs" @click="goBottom"><p>contact</p></div>
    <div class="backToTop" @click="goTop">
      <img src="../static/img/VectorTop.png" alt="" />
      <p>TOP</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scrollTop: 0,
      showQR: false,
      reservationMutex: false,
    };
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    },
    goTop() {
      let timer = setInterval(() => {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.body.scrollTop = osTop + ispeed;
        document.documentElement.scrollTop = document.body.scrollTop;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    goBottom() {
      document.body.scrollTop = 100000
      document.documentElement.scrollTop = 100000
    },
    reservation() {
      this.reservationMutex = !this.reservationMutex;
      this.showQR = false;
    },
    wechat() {
      this.showQR = !this.showQR;
      this.reservationMutex = false;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
<style scoped>
/*悬浮按钮*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.floatButton {
  position: fixed;
  right: 26px;
  bottom: 100px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.portAppointment {
  width: 60px;
  height: 60px;
  padding-top: 3px;
  background: #011b77;
  opacity: 0.8;
  color: #ffffff;
  border: 3px solid #bababa;
  box-sizing: border-box;
  border-radius: 19px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weChat,
.contactUs {
  margin-top: 10px;
  width: 60px;
  height: 60px;
  padding-top: 6px;
  background: #fafafa;
  opacity: 0.8;
  color: #0e0e0e;
  border: 3px solid #bababa;
  box-sizing: border-box;
  border-radius: 19px;
  text-align: center;
  font-size: 10px;
}

.contactUs{
  font-size: 14px;
  padding-top: 15px;
}

.backToTop {
  margin-top: 10px;
  width: 60px;
  height: 54px;
  padding-top: 5px;
  background: #fafafa;
  opacity: 0.8;
  color: #041d72;
  border: 3px solid #bababa;
  box-sizing: border-box;
  border-radius: 19px;
  text-align: center;
  font-size: 12px;
}

.backToTop > img {
  height: 16px;
}

.wechatQR{
  height: 180px;
  position: absolute;
  right: 65px;
  top: 30px;
}
</style>
