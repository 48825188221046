import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: (resolve) => {
      require(["@/views/Home"], resolve);
    },
  },
  {
    path: "/advantage",
    name: "Advantage",
    component: (resolve) => {
      require(["@/views/AdvantagePage"], resolve);
    },
  },
  {
    path: "/advantage/details",
    name: "AdvantageDetail",
    component: (resolve) => {
      require(["@/views/AdvantageDetailPage"], resolve);
    },
  },
  {
    path: "/success",
    name: "Success",
    component: (resolve) => {
      require(["@/views/SuccessPage"], resolve);
    },
  },
  {
    path: "/introduction",
    name: "Introduction",
    component: (resolve) => {
      require(["@/views/IntroductionPage"], resolve);
    },
  },
  {
    path: "/joinUs",
    name: "JoinUs",
    component: (resolve) => {
      require(["@/views/JoinUs"], resolve);
    },
  },
  {
    path: "/consult",
    name: "Consult",
    component: (resolve) => {
      require(["@/views/ConsultPage"], resolve);
    },
    meta: {
      isConsultTurn: true,
    },
    children: [
      {
        path: "news1",
        component: (resolve) => {
          require(["@/views/ConsultNewsPages/news1"], resolve);
        },
      },
      {
        path: "news2",
        component: (resolve) => {
          require(["@/views/ConsultNewsPages/news2"], resolve);
        },
      },
      {
        path: "news3",
        component: (resolve) => {
          require(["@/views/ConsultNewsPages/news3"], resolve);
        },
      },
      {
        path: "news4",
        component: (resolve) => {
          require(["@/views/ConsultNewsPages/news4"], resolve);
        },
      },
      {
        path: "news5",
        component: (resolve) => {
          require(["@/views/ConsultNewsPages/news5"], resolve);
        },
      },
      {
        path: "news6",
        component: (resolve) => {
          require(["@/views/ConsultNewsPages/news6"], resolve);
        },
      },
    ],
  },
  {
    path: "/En",
    name: "ENHome",
    component: (resolve) => {
      require(["@/views/ENHome"], resolve);
    },
    children: [],
  },
  {
    path: "/En/introduction",
    name: "ENIntroductionPage",
    component: (resolve) => {
      require(["@/views/ENIntroductionPage"], resolve);
    },
  },
  {
    path: "/EN/success",
    name: "ENSuccess",
    component: (resolve) => {
      require(["@/views/ENSuccessPage"], resolve);
    },
  },
  {
    path: "/EN/advantage",
    name: "ENAdvantage",
    component: (resolve) => {
      require(["@/views/ENAdvantage"], resolve);
    },
  },
  {
    path: "/EN/aboutUs",
    name: "ENAboutUs",
    component: (resolve) => {
      require(["@/views/ENAboutUs"], resolve);
    },
  },
  {
    path: "/aboutUs",
    NAME: "AboutUs",
    component: (resolve) => {
      require(["@/views/AboutUs"], resolve);
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
